







































import { DaoDetailSettingViewModel } from '@/modules/community-organize/viewmodels/dao-detail-setting-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Inject({}) setting!: DaoDetailSettingViewModel
}
